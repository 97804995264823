/* You can add global styles to this file, and also import other style files */
@import "bootstrap/dist/css/bootstrap.css";
@import url('https://rsms.me/inter/inter.css');
@import url('assets/css/global-1.css');
/* @import "@angular/material/prebuilt-themes/indigo-pink.css"; */




html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.ngx-toastr {
  width: 350px !important;
}
.toast-info {
  background-color: #5BB8FF !important;
  }

.toast-warning {
  background-color: #FFCC00 !important;
}


@media print {
  #Header, #Footer { display: none !important; }
  @page { margin: 0; }
  body { margin: 1.6cm; }
}

