.display-6{
  font-family: 'Inter', sans-serif;
  font-size: 34px;
  color: #353f56;
  text-decoration: none solid rgb(53, 63, 86);
}
.sub-dis{
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  color: #353f56;
  text-decoration: none solid rgb(10, 10, 10);
}

.lnk-grn{
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: #549975;
  cursor: pointer;
  text-decoration: underline solid rgb(84, 153, 117);
}
.lnk-grn:hover{
  color: #549975;
}



.sqr-btn {
  height: 59px;
  width: 145px;
  border: 1px outset #adb5bd;
  background-color: #549975;
  background-size: cover;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #ffffff;
  cursor:pointer;
  text-align: center;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.4);
}

.sqr-btn:hover {
  border: 1px solid #549975;
  background-color: #ffffff;
  background-size: cover;
  color: #549975;
  text-decoration: none solid rgb(84, 153, 117);
  text-align: center;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.4);
}

::placeholder {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #bfbfbf;
  text-decoration: none solid rgb(191, 191, 191);
  line-height: 26px;
  opacity: 1;
}



.apl-det{
  padding-top: 40px;
  margin-left: 53px;
  margin-right: 25px;
  font-size: 18px;
}
.cntnt-1{
  margin-top: 40px;
}
#txt-01{
  width: 60%;
  padding-right: 20px;
  font-size: 18px;
  color: #0a0a0a;

}
#txt-02{
  width: 40%;
  font-size: 18px;
  padding-left: 130px;
  color: #0a0a0a;
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
}




.navbar{
  background-color:#3b4a75;
  width: 100%;
}

#header-logo
{
  display: block;

}
#header-logo-sml{
  display: none;
}

/* .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #549975 !important;
} */

.mat-mdc-tab-group.mat-primary .mat-ink-bar, .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #549975 !important;
}

::ng-deep .mat-mdc-menu-content .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text{
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 14px);
}


/*ZB Changed MDC */

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab .mdc-tab__content
{
  font-family: 'Inter', sans-serif !important;
  font-size: 14pt !important;
}

.full-width-dialog .mat-mdc-dialog-container {
  max-width: 100vw !important;
}

ngx-extended-pdf-viewer #mainContainer{
  min-width: 0 !important;
}



.srch-btn{
  padding-top: 5px !important;
}
.srch-txt{
  padding-top: 20px;
  padding-left: 40px;
}
.srch-inpt{
  font-family: 'Inter', sans-serif;
  /* width: 338px; */
  height: 59px;
  margin-right: 10px;
  border: 1px solid #8c8c8c;
  border-radius: 4px;
  background-color: #ffffff;
  background-size: cover;
  font-size: 14px;
  color: #121212;
  text-decoration: none solid rgb(18, 18, 18);

}
.srch-inpt-cntr{
  padding-left: 40px;
}


/* ---------------------------------------------------------- */

@media screen  and (max-width: 414px)
               and (orientation: portrait){
  .apl-det{
    padding-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 18px;
  }

  .navbar{
    background-color:#3b4a75;

  }
  #header-logo
  {
    display: none;

  }
  #header-logo-sml{
    display: block;
  }
  .sqr-btn {
    width: 130px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-mdc-tab .mdc-tab__content
{
  font-family: 'Inter', sans-serif !important;
  font-size: 10pt !important;
}

.full-width-dialog .mat-mdc-dialog-container {
  max-width: 100% !important;
  width: 100%;
}


::ng-deep ngx-extended-pdf-viewer #mainContainer{
  min-width: 0 !important;
}

input.srch-inpt.ng-pristine.ng-valid.ng-touched{
  width: 170px;
  max-width: 170px !important;
}
::ng-deep .srch-inpt{
  width: 170px;
  max-width: 170px !important;

}


.vwr{

}

.mat-mdc-dialog-container {
  max-width: 100vw !important;
}

}



